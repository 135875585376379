<template>
  <v-app id="inspire">
    <v-container>
      <top-bar :navpos="items" />
      <v-main>
        <main-navigation />
      </v-main>
      <footer-bar/>
    </v-container>
  </v-app>
</template>

<script>
import MainNavigation from "../../components/MainNavigation.vue";
import TopBar from "../../components/TopBar.vue";
import FooterBar from "../../components/Footer.vue";

export default {
  components: { MainNavigation, TopBar, FooterBar },
  name: "Main",
  props: {},
  data() {
    return {
      items: [
        {
          text: "Main",
          disabled: true,
          href: "main"
        }
      ]
    };
  },
  methods: {},
  computed: {}
};
</script>
